export const home_page_main = "/assets/backgrounds/home_page_main.png";
export const reporting_1 = "/assets/backgrounds/reporting_1.png";
export const reporting_2 = "/assets/backgrounds/reporting_2.png";
export const reporting_3 = "/assets/backgrounds/reporting_3.png";
export const reporting_4 = "/assets/backgrounds/reporting_4.png";
export const reporting_5 = "/assets/backgrounds/reporting_5.png";
export const team_member_1 = "/assets/backgrounds/team_member_1.png";
export const team_member_2 = "/assets/backgrounds/team_member_2.png";
export const team_member_3 = "/assets/backgrounds/team_member_3.png";
export const partner_1 = "/assets/backgrounds/partner_1.png";
export const partner_2 = "/assets/backgrounds/partner_2.png";
export const partner_3 = "/assets/backgrounds/partner_3.png";