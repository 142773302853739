import {
    AnnouncementAction,
    AnnouncementActionTypes,
    AnnouncementState
} from "./types";

const initialState: AnnouncementState = {
    count: 0,
    announcements: [],
    uploadedImages: [],
    contentTypes: []
}

export const announcementReducer = (state = initialState, action: AnnouncementAction): AnnouncementState => {
    switch (action.type) {
        case AnnouncementActionTypes.GET_ANNOUNCEMENT_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.payload,
            }
        case AnnouncementActionTypes.SEARCH_ANNOUNCEMENTS:
            return {
                ...state,
                count: action.payload.count,
                announcements: action.payload.values,
            }
        case AnnouncementActionTypes.ADD_UPLOADED_IMAGE:
            let tempForAdd: Array<string> = [];
            tempForAdd = tempForAdd.concat(state.uploadedImages, action.payload);
            return {
                ...state,
                uploadedImages: tempForAdd,
            }
        case AnnouncementActionTypes.DELETE_UPLOADED_IMAGE:
            let tempForDelete: Array<string> = state.uploadedImages;
            action.payload.forEach((item) => {
                const index = tempForDelete.indexOf(item);
                if (index > -1) {
                    tempForDelete.splice(index, 1);
                }
            });
            return {
                ...state,
                uploadedImages: tempForDelete,
            }
        case AnnouncementActionTypes.CLEAR_UPLOADED_IMAGE:
            return {
                ...state,
                uploadedImages: [],
            }
        default:
            return state;
    }
}