import { combineReducers } from "redux";

import { authReducer } from "../../pages/auth/reducer";
import { announcementReducer } from "../../pages/admin/Announcement/reducer";
import { announcementUserReducer } from "../../pages/default/Announcements/reducer";
import { hashtagReducer } from "../../pages/admin/Hashtag/reducer";
import { uiReducer } from "../../UISettings/reducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    announcement: announcementReducer,
    announcementUser: announcementUserReducer,
    hashtag: hashtagReducer
});

export type RootState = ReturnType<typeof rootReducer>;