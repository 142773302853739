import { Dispatch } from "react"
import qs from "qs"

import http from "../../../http_comon"
import {
    IHashtag,
    IHashtagInfo,
    HashtagAction,
    HashtagActionTypes,
    ISearchHashtags,
} from "./types"
import { ServerError } from "../../../store/types"

export const SearchHashtags = (page: number, rowsPerPage: number, name: string, isAscOrder: boolean, orderBy: string) => {
    return async (dispatch: Dispatch<HashtagAction>) => {
        try {
            let response = await http.get<ISearchHashtags>(`api/Hashtag/Search`, {
                params: {
                    page: page,
                    rowsPerPage: rowsPerPage,
                    name: name,
                    isAscOrder: isAscOrder,
                    orderBy: orderBy
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })

            dispatch({
                type: HashtagActionTypes.SEARCH_HASHTAGS,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const GetHashtags = () => {
    return async (dispatch: Dispatch<HashtagAction>) => {
        try {
            let response = await http.get<Array<IHashtagInfo>>(`api/Hashtag/Get`)

            dispatch({
                type: HashtagActionTypes.GET_HASHTAGS,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const GetByIdHashtag = (id: number) => {
    return async (dispatch: Dispatch<HashtagAction>) => {
        try {
            let response = await http.get<IHashtag>(`api/Hashtag/GetById/${id}`)

            dispatch({
                type: HashtagActionTypes.GET_BY_ID_HASHTAG,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const CreateHashtag = (data: IHashtag) => {
    return async () => {
        try {
            await http.post("api/Hashtag/Create", data);
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const UpdateHashtag = (id: number, data: IHashtag) => {
    return async () => {
        try {
            await http.put<IHashtag>(`api/Hashtag/Update/${id}`, data);
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const DeleteHashtag = (id: number) => {
    return async () => {
        try {
            await http.delete(`api/Hashtag/Delete/${id}`);
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const DeleteHashtags = (ids: readonly number[]) => {
    return async () => {
        try {
            await http.delete(`api/Hashtag/Delete`, {
                params: {
                    ids: ids,
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}