import { Dispatch } from "react"
import { Dayjs } from "dayjs"
import qs from "qs"

import http from "../../../http_comon"
import {
    IAnnouncementItem,
    IAnnouncementResponse,
    AnnouncementUserActionTypes,
    AnnouncementUserAction,
} from "./types"
import { ServerError } from "../../../store/types"

export const GetNovelties = (searchBy: string, search: string, page: number, rowsPerPage: number, date: Dayjs | null) => {
    return async (dispatch: Dispatch<AnnouncementUserAction>) => {
        try {
            let response = await http.get<IAnnouncementResponse>(`api/Announcement/GetNovelties`, {
                params: {
                    searchBy: searchBy,
                    search: search,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    date: date
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            });

            dispatch({
                type: AnnouncementUserActionTypes.GET_NOVELTIES,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const AddNovelties = (searchBy: string, search: string, page: number, rowsPerPage: number, date: Dayjs | null) => {
    return async (dispatch: Dispatch<AnnouncementUserAction>) => {
        try {
            let response = await http.get<IAnnouncementResponse>(`api/Announcement/GetNovelties`, {
                params: {
                    searchBy: searchBy,
                    search: search,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    date: date
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            });

            dispatch({
                type: AnnouncementUserActionTypes.ADD_NOVELTIES,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}
