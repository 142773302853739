import {
    AnnouncementUserAction,
    AnnouncementUserActionTypes,
    AnnouncementUserState,
    IAnnouncementItem
} from "./types";

const initialState: AnnouncementUserState = {
    count: 0,
    announcements: [],
}

export const announcementUserReducer = (state = initialState, action: AnnouncementUserAction): AnnouncementUserState => {
    switch (action.type) {
        case AnnouncementUserActionTypes.GET_NOVELTIES:
            return {
                ...state,
                count: action.payload.count,
                announcements: action.payload.values,
            }
        case AnnouncementUserActionTypes.ADD_NOVELTIES:
            let tempArr: Array<IAnnouncementItem> = [];
            if (state.announcements.length !== 0)
                tempArr = tempArr.concat(state.announcements, action.payload.values);
            else
                tempArr = action.payload.values;
            return {
                ...state,
                count: action.payload.count,
                announcements: tempArr,
            }
        default:
            return state;
    }
}