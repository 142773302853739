import {
    HashtagAction,
    HashtagActionTypes,
    HashtagState
} from "./types";

const initialState: HashtagState = {
    selectedHashtag: {
        name: ""
    },
    count: 0,
    hashtags: []
}

export const hashtagReducer = (state = initialState, action: HashtagAction): HashtagState => {
    switch (action.type) {
        case HashtagActionTypes.GET_HASHTAGS:
            return {
                ...state,
                hashtags: action.payload,
            }
        case HashtagActionTypes.SEARCH_HASHTAGS:
            return {
                ...state,
                count: action.payload.count,
                hashtags: action.payload.values,
            }
        case HashtagActionTypes.GET_BY_ID_HASHTAG:
            return {
                ...state,
                selectedHashtag: action.payload,
            }
        case HashtagActionTypes.CREATE_HASHTAG:
            return {
                ...state,
                ...action.payload
            }
        case HashtagActionTypes.UPDATE_HASHTAG:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}