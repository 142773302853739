import { Dayjs } from "dayjs";

export enum AnnouncementActionTypes {
    GET_ANNOUNCEMENT_CONTENT_TYPES = "GET_ANNOUNCEMENT_CONTENT_TYPES",
    SEARCH_ANNOUNCEMENTS = "SEARCH_ANNOUNCEMENTS",
    ADD_UPLOADED_IMAGE = "ADD_UPLOADED_IMAGE",
    DELETE_UPLOADED_IMAGE = "DELETE_UPLOADED_IMAGE",
    CLEAR_UPLOADED_IMAGE = "CLEAR_UPLOADED_IMAGE"
}

export interface IAnnouncementImage {
    name: string
}

export interface IAnnouncementInfo {
    id: number,
    title: string,
    imagePreview: string,
    hashtag: string
    date: Dayjs
}

export interface IAnnouncementContent {
    content: string,
    additionalContent: string,
    priority: number,
    contentTypeId: number
}
export interface IAnnouncementRequest {
    title: string,
    description: string,
    imagePreview: string,
    hashtagsId: Array<number>,
    date: Dayjs | null,
    announcementTime: string,
    keyWords: string,
    contents: Array<IAnnouncementContent>,
}

export interface IAnnouncementContentType {
    id: number,
    name: string
}

export interface ISearchAnnouncements {
    count: number,
    values: Array<IAnnouncementInfo>
}


export interface AnnouncementState {
    count: number,
    announcements: Array<IAnnouncementInfo>,
    uploadedImages: Array<string>,
    contentTypes: Array<IAnnouncementContentType>
}


export interface GetAnnouncementContentTypesAction {
    type: AnnouncementActionTypes.GET_ANNOUNCEMENT_CONTENT_TYPES,
    payload: Array<IAnnouncementContentType>
}

export interface SearchAnnouncementsAction {
    type: AnnouncementActionTypes.SEARCH_ANNOUNCEMENTS,
    payload: ISearchAnnouncements
}

export interface AddUploadedImageAction {
    type: AnnouncementActionTypes.ADD_UPLOADED_IMAGE,
    payload: string
}

export interface DeleteUploadedImageAction {
    type: AnnouncementActionTypes.DELETE_UPLOADED_IMAGE,
    payload: readonly string[]
}
export interface ClearUploadedImageAction {
    type: AnnouncementActionTypes.CLEAR_UPLOADED_IMAGE
}


export type AnnouncementAction = GetAnnouncementContentTypesAction | SearchAnnouncementsAction | AddUploadedImageAction | DeleteUploadedImageAction | ClearUploadedImageAction;