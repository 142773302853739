import { ListItemButton, Button, Box, styled } from "@mui/material";

export const MoreInfoButtonStyle = styled(Button)(({ theme }) => ({
    fontFamily: "Fixel Display, Mulish",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    minWidth: "auto",
    height: "auto",
    padding: "10px 15px 6px 15px",
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "12px",
    textTransform: "none",
    "&:hover": { background: theme.palette.primary.main },
    "&& .MuiTouchRipple-child": { backgroundColor: theme.palette.primary.main },
}));

export const ChronologyBoxStyle = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.dark,
    borderRadius: "8px",
    width: "290px",
    height: "216px",
    padding: "16px 8px 0px 16px",
}));