import {
    AppBar,
    Box,
    Container,
    useTheme,
    Typography,
    Grid
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

import LinkRouter from "../../components/LinkRouter";

import { logo } from "../../assets/logos";
import { instagram_outlined, telegram_outlined } from "../../assets/icons";


interface IAppBarItem {
    lable: string,
    path: string,
}

const Header = () => {
    const { palette } = useTheme();
    const location = useLocation();

    const { DeleteAnnouncementImages } = useActions();
    const { uploadedImages } = useTypedSelector((store) => store.announcement);

    const navigate = useNavigate();

    const [pages, setPages] = useState<Array<IAppBarItem>>([
        { lable: 'Анонси', path: '/admin' },
        { lable: 'Проєкти', path: '/admin/projects' },
        { lable: 'Теги', path: '/admin/hashtags' },
    ]);

    const handleUnload = async () => {
        try {
            await DeleteAnnouncementImages(uploadedImages);
        }
        catch (ex) {
        }
    }

    return (
        <AppBar component="header" elevation={0} position="static" sx={{ height: "64px", borderBottom: (theme) => `1px solid ${theme.palette.primary.light}` }}>
            <Container sx={{ height: "100%", maxWidth: { xl: "xl", lg: "lg", md: "md" } }}>
                <Grid container spacing={0} sx={{ height: "100%", display: "flex", alignItems: "center", px: "65px" }}>
                    <Grid item xs={2}>
                        <LinkRouter underline="none" color="unset" to="/" sx={{ height: "35px" }} onClick={handleUnload}>
                            <img
                                src={logo}
                                alt="logo"
                            />
                        </LinkRouter>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: "space-between" }}>
                        {pages.map((page, index) => (
                            <LinkRouter key={index} underline={(location.pathname == page.path) ? "always" : "none"} color={(location.pathname == page.path) ? "primary.main" : "inherit"} to={page.path} onClick={handleUnload}>
                                <Typography fontSize="14px" lineHeight="22px" fontFamily="Fixel Display, Mulish" sx={{ padding: "12px 16px" }}>
                                    {page.lable}
                                </Typography>
                            </LinkRouter>
                        ))}
                    </Grid>
                    <Grid item xs={5} />

                </Grid>
            </Container>
        </AppBar >
    );
};

export default Header;