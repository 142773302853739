import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { ITextEditor } from "./type";

import "react-quill/dist/quill.snow.css";


const TextEditor: React.FC<ITextEditor> = ({ value, setValue, error }) => {

    useEffect(() => {
    }, [error]);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: [] }],
            [{ font: [] }],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [{ color: ["red", "#785412"] }],
            [{ background: ["red", "#785412"] }],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",
        "size",
        "font",
    ];

    const handleProcedureContentChange = (content: any) => {
        setValue(content);
    };

    return (
        <>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={handleProcedureContentChange}
            />
            {error != null &&
                <p className={"MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-1cytzy5-MuiFormHelperText-root"} >{error}</p>}
        </>
    );
}

export default TextEditor;