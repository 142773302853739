import TextField, { TextFieldProps } from '@mui/material/TextField';
import { alpha, styled, Grid } from "@mui/material";

export const SearchTextFieldStyle = styled(TextField)<TextFieldProps>(({ theme }) => ({
    width: "300px",

    "& fieldset": {
        borderRadius: "6px",
        borderColor: "#AFA291",
    },
    InputLabelProps: {
        color: theme.palette.mode != "dark" ? "#7E7E7E" : "#fff",

    },
    "& .MuiInputBase-input": {
        padding: "6px 11px",

    },
    "& .MuiOutlinedInput-root": {
        color: "#AFA291",
        fontSize: "14px",
        lineHeight: "22px",
        "& fieldset": {
            borderColor: "#AFA291"
        },
        "&:hover fieldset": {
            borderColor: "#ffffff",
            backgroundColor: alpha("#B7B7B7", 0.15)
        },
        "&.Mui-focused fieldset": {
            border: "1px solid",
            borderColor: "#ffffff"
        },
    }
}));

export const GridAnnouncementsStyle = styled(Grid)(({ theme }) => ({
    height: "fit-content",
    display: "flex",
    flexWrap: 'wrap',
    [theme.breakpoints.only('xl')]: {
        "&>*:nth-of-type(4n)": {
            marginRight: "0px"
        }
    },
    [theme.breakpoints.only('lg')]: {
        "&>*:nth-of-type(3n)": {
            marginRight: "0px"
        }
    },
    [theme.breakpoints.only('md')]: {
        "&>*:nth-of-type(2n)": {
            marginRight: "0px"
        }
    },
}));