import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SignIn from './page';


const Auth = () => {
    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey="6Lc_nf4eAAAAACeBl6BhMih3uCTngIikJng_nlYG">
                <SignIn />
            </GoogleReCaptchaProvider>
        </>
    )
}

export default Auth;