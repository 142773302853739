import {
    Box,
    Typography,
    IconButton,
    Grid,
    ListItem,
    ListItemIcon,
    Paper,
    useTheme,
    ImageList,
    ImageListItem,
    Button,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
    ButtonGroup,
} from '@mui/material'
import {
    ArrowBackIosNewOutlined,
    ArrowForwardIosOutlined,
    KeyboardArrowDown,
    PhotoOutlined,
    Search,
} from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

import LinkRouter from '../../../components/LinkRouter';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent';

import { GridAnnouncementsStyle, SearchTextFieldStyle } from './styled';

import { arrow_circle, audit_outlined, read_outlined, team_outlined } from '../../../assets/icons';

import { IBreadcrumb } from '../../../components/BreadcrumbsComponent/types';
import AnnouncementItem from '../../../components/AnnouncementItem';


interface ISearchBy {
    label: string,
    property: string
}
const options: Array<ISearchBy> = [{ label: 'За заголовком', property: "title" }, { label: 'За описом', property: "description" }, { label: 'За тегом', property: "hashtag" }];

const Announcements = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { GetNovelties, AddNovelties } = useActions();
    const { announcements, count } = useTypedSelector((store) => store.announcementUser);


    const [breadcrumbs, setBreadcrumbs] = useState<Array<IBreadcrumb>>([
        { name: "Анонси", urlSlug: "" },
    ]);

    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [searchBy, setSearchBy] = useState<string>("title");
    const [search, setSearch] = useState<string>("");
    const [date, setDate] = useState<Dayjs | null>(null);

    useEffect(() => {
        if (page === 1)
            getData();
        else
            addData();
    }, [page])

    const getData = async () => {
        try {
            await GetNovelties(searchBy, search, page, rowsPerPage, date);
        } catch (ex) {
        }
    };
    const addData = async () => {
        try {
            await AddNovelties(searchBy, search, page, rowsPerPage, date);
        } catch (ex) {
        }
    };

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setSearchBy(options[index].property)
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container spacing={0} sx={{ pt: "20px", pb: "120px" }}>
            <Grid item xs={12} sx={{ mb: "20px" }}>
                <BreadcrumbsComponent elements={breadcrumbs} />
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h1" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px" >
                    Анонси
                </Typography>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                <Button
                    ref={anchorRef}
                    variant="contained"
                    onClick={handleToggle}
                    sx={{
                        height: "32px",
                        textTransform: "inherit",
                        fontsize: "14px",
                        lineHeight: "22px",
                        px: "16px",
                        background: palette.primary.dark,
                        mr: "12px"
                    }}
                    endIcon={<KeyboardArrowDown />}
                >
                    {options[selectedIndex].label}
                </Button>
                <Popper
                    sx={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={`search_by_${option.property}`}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <SearchTextFieldStyle
                    value={search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSearch(e.target.value) }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.code == 'Enter') {
                            if (page == 1)
                                getData();
                            else
                                setPage(1)
                        }
                    }}
                    placeholder="Шукати..."
                />
                <IconButton size="large"
                    disableRipple
                    sx={{
                        p: "4px 0px",
                        ml: "6px"
                    }}
                    onClick={() => {
                        if (page == 1)
                            getData();
                        else
                            setPage(1)
                    }}
                >
                    <Search />
                </IconButton>
            </Grid>
            <GridAnnouncementsStyle item xs={12} sx={{ marginTop: "34px" }}>
                {announcements.map((row, index) => {
                    return (
                        <AnnouncementItem key={row.urlSlug} urlSlug={row.urlSlug} title={row.title} description={row.description} imagePreview={row.imagePreview} date={row.date} announcementTime={row.announcementTime} hashtags={row.hashtags} />
                    );
                })}
            </GridAnnouncementsStyle>
            {announcements.length !== count && <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                        textTransform: "inherit",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "500",
                        fontFamily: "Fixel Display, Mulish",
                        borderColor: palette.primary.main,
                        borderRadius: "12px",
                        p: "8px 26px"
                    }}
                    onClick={() => { setPage(page + 1) }}
                >
                    Показати ще...
                </Button>
            </Grid>}
        </Grid>
    )
}

export default Announcements