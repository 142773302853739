import { Dispatch } from "react"
import qs from "qs"

import http from "../../../http_comon"
import {
    IAnnouncementInfo,
    AnnouncementAction,
    AnnouncementActionTypes,
    ISearchAnnouncements,
    IAnnouncementContent,
    IAnnouncementRequest,
    IAnnouncementImage,
    IAnnouncementContentType,
} from "./types"
import { ServerError } from "../../../store/types"

export const GetContentTypes = () => {
    return async (dispatch: Dispatch<AnnouncementAction>) => {
        try {
            let response = await http.get<Array<IAnnouncementContentType>>(`api/Announcement/GetContentTypes`)

            dispatch({
                type: AnnouncementActionTypes.GET_ANNOUNCEMENT_CONTENT_TYPES,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const SearchAnnouncements = (page: number, rowsPerPage: number, name: string, isAscOrder: boolean, orderBy: string) => {
    return async (dispatch: Dispatch<AnnouncementAction>) => {
        try {
            let response = await http.get<ISearchAnnouncements>(`api/Announcement/AdminSearch`, {
                params: {
                    page: page,
                    rowsPerPage: rowsPerPage,
                    name: name,
                    isAscOrder: isAscOrder,
                    orderBy: orderBy
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })

            dispatch({
                type: AnnouncementActionTypes.SEARCH_ANNOUNCEMENTS,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const CreateAnnouncementImage = (base64: string) => {
    return async (dispatch: Dispatch<AnnouncementAction>) => {
        try {
            let response = await http.post<IAnnouncementImage>(`api/AnnouncementImage/Create`, base64)

            dispatch({
                type: AnnouncementActionTypes.ADD_UPLOADED_IMAGE,
                payload: response.data.name
            })

            return Promise.resolve(response.data);
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const DeleteAnnouncementImages = (paths: readonly string[]) => {
    return async (dispatch: Dispatch<AnnouncementAction>) => {
        try {
            await http.delete(`api/AnnouncementImage/Delete`, {
                params: {
                    paths: paths,
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })

            dispatch({
                type: AnnouncementActionTypes.DELETE_UPLOADED_IMAGE,
                payload: paths
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const CreateAnnouncement = (values: IAnnouncementRequest) => {
    return async (dispatch: Dispatch<AnnouncementAction>) => {
        try {
            let response = await http.post(`api/Announcement/Create`, values)

            dispatch({
                type: AnnouncementActionTypes.CLEAR_UPLOADED_IMAGE
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const DeleteAnnouncements = (ids: readonly number[]) => {
    return async () => {
        try {
            await http.delete(`api/Announcement/Delete`, {
                params: {
                    ids: ids,
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}