import { createTheme, hexToRgb, rgbToHex } from "@mui/material";

const darkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1185,
            xl: 1440,
        },
    },
    palette: {
        mode: "dark",
        background: {
            default: "#21212B",
            paper: "#21212B !important",
        },
        primary: {
            main: "#FF493B",
            dark: "#753130",
            light: "#FF968F"
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#AFA291"
        }
    },
    typography: {
        h1: {
            fontSize: "38px",
            lineHeight: "46px"
        },
        h2: {
            fontSize: "30px",
            lineHeight: "38px"
        },
        h3: {
            fontSize: "24px",
            lineHeight: "32px"
        },
        h4: {
            fontSize: "20px",
            lineHeight: "28px"
        },
        h5: {
            fontSize: "16px",
            lineHeight: "24px"
        },
        h6: {
            fontSize: "16px",
            lineHeight: "24px"
        },
        subtitle1: {
            fontSize: "16px",
            lineHeight: "24px"
        },
        fontFamily: [
            "Mulish",
            'Fixel Display',
            'Druk Wide',
            'Actay Wide',
            "sans-serif"
        ].join(',')
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "&::-webkit-scrollbar-corner": {
                    backgroundColor: hexToRgb("#181A1B")
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: hexToRgb("#181A1B")
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& .MuiDialog-paper": {
                        backgroundImage: "none"
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundImage: "none"
                }
            }
        },
        MuiContainer: {
            defaultProps: {
                style: {
                    padding: 0
                }
            }
        },
        MuiAppBar: {
            defaultProps: {
                color: "inherit"
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none"
                }
            }
        }
    },
});

export default darkTheme;