import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from 'react'

import { ChronologyBoxStyle } from "./styled";

import { arrow_down, arrow_left, arrow_right, read_outlined_white, reconciliation_outlined, snippets_outlined, solution_outlined } from "../../../assets/icons";


interface IChronology {
    date: string,
    icon: string,
    description: string
}

const Chronology = () => {
    const { palette } = useTheme();

    const [chronology, setChronology] = useState<Array<IChronology>>([
        { date: "19 лютого 2022 р.", icon: read_outlined_white, description: "Перша подія в Тернополі. Квартирник для молоді як проєкту About Ukraine" },
        { date: "лютий 2022 р. - листопад 2022 р.", icon: reconciliation_outlined, description: "Подорожі країною з культурними подіями та пошук свого я, як ініціативи" },
        { date: "4 листопада 2022 р.", icon: snippets_outlined, description: "Офіційна реєстрація Громадської організації “Про тебе і Україну” за допомоги проєкту Let’s NGO" },
        { date: "грудень 2023 р.", icon: solution_outlined, description: "Створено виставкову залу в Здолбунівському краєзнавчому музеї “Мультикультурний Здолбунів”" },
        { date: "вересень 2023 р.", icon: solution_outlined, description: "Участь у міжнародному семінарі “GROW” з представники громадських організацій Данії" },
        { date: "літо 2023 р.", icon: snippets_outlined, description: "Реалізація проєкту “Культура Єднання”" },
        { date: "весна 2023 р.", icon: reconciliation_outlined, description: "Створено “Культурно-освітній простір Здолбунова” у Здолбунівському  краєзнавчому музеї" },
        { date: "квітень 2023 р.", icon: read_outlined_white, description: "Разом з ГО “ЕНО” створено гру-турнір “Українофіли”" },
        { date: "липень 2024 р. - жовтень 2024 року", icon: solution_outlined, description: "Жовтень 2024 року - створено інтерактивну експозицію “Колишня в’язниця” у Здолбунівському краєзнавчому музеї" },
        { date: "серпень 2024 р. - жовтень 2024 р.", icon: snippets_outlined, description: "Реалізація проєкту “Мистецтво діалогу”" },
    ]);

    return (
        <>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: "22px" }}>
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[0].date}
                        </Typography>
                        <img
                            src={chronology[0].icon}
                            alt={chronology[0].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[0].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_right}
                    alt="arrow_right"
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[1].date}
                        </Typography>
                        <img
                            src={chronology[1].icon}
                            alt={chronology[1].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[1].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_right}
                    alt="arrow_right"
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[2].date}
                        </Typography>
                        <img
                            src={chronology[2].icon}
                            alt={chronology[2].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[2].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_right}
                    alt="arrow_right"
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[3].date}
                        </Typography>
                        <img
                            src={chronology[3].icon}
                            alt={chronology[3].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[3].description}
                    </Typography>
                </ChronologyBoxStyle>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", p: "10px 137px 10px 0px" }}>
                <img
                    src={arrow_down}
                    alt="arrow_down"
                />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[4].date}
                        </Typography>
                        <img
                            src={chronology[4].icon}
                            alt={chronology[4].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[4].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_left}
                    alt="arrow_left"
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[5].date}
                        </Typography>
                        <img
                            src={chronology[5].icon}
                            alt={chronology[5].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[5].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_left}
                    alt="arrow_left"
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[6].date}
                        </Typography>
                        <img
                            src={chronology[6].icon}
                            alt={chronology[6].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[6].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_left}
                    alt="arrow_left"
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[7].date}
                        </Typography>
                        <img
                            src={chronology[7].icon}
                            alt={chronology[7].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[7].description}
                    </Typography>
                </ChronologyBoxStyle>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", p: "10px 0px 10px 137px" }}>
                <img
                    src={arrow_down}
                    alt="arrow_down"
                />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[8].date}
                        </Typography>
                        <img
                            src={chronology[8].icon}
                            alt={chronology[8].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[8].description}
                    </Typography>
                </ChronologyBoxStyle>
                <img
                    src={arrow_right}
                    alt="arrow_right"
                    style={{ padding: "0px 10px" }}
                />
                <ChronologyBoxStyle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "5px" }}>
                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" fontWeight="bold" >
                            {chronology[9].date}
                        </Typography>
                        <img
                            src={chronology[9].icon}
                            alt={chronology[9].icon}
                        />
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" fontWeight="600" color={palette.text.secondary}>
                        {chronology[9].description}
                    </Typography>
                </ChronologyBoxStyle>
            </Grid>
        </>
    )
}

export default Chronology