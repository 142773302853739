import { Box, IconButton, Typography, Paper, useTheme } from '@mui/material'
import { FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import LinkRouter from '../LinkRouter'
import { BoxStyle } from './styled'

import { FavoriteBorder } from '@mui/icons-material'

import { useActions } from '../../hooks/useActions'

require('dayjs/locale/uk')

interface Props {
    urlSlug: string,
    title: string,
    description: string,
    imagePreview: string,
    date: Dayjs,
    announcementTime: string,
    hashtags: Array<string>
}

const AnnouncementItem: FC<Props> = ({ urlSlug, title, description, imagePreview, date, announcementTime, hashtags }) => {
    const { palette } = useTheme();

    return (
        <Box sx={{ marginRight: "32px", marginBottom: "28px" }}>
            <LinkRouter underline="none" color="unset" to={`/announcements/${urlSlug}`}
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }}>
                <BoxStyle>
                    <Box sx={{ position: "relative", width: "279px", height: "168px" }}>
                        <img
                            style={{ width: "279px", height: "168px", objectFit: "contain", borderRadius: "7px" }}
                            src={imagePreview}
                            alt="image"
                        />
                        <Box sx={{ display: "flex", position: "absolute", bottom: "9px", left: "8px" }}>
                            {hashtags.map((row, index) => {
                                return (
                                    <Typography
                                        key={`announcement_hashtag_${index}`}
                                        variant="h6"
                                        fontFamily="Fixel Display, Mulish"
                                        fontSize="9px" lineHeight="16px"
                                        fontWeight="600"
                                        sx={{

                                            background: palette.primary.main,
                                            borderRadius: "4px",
                                            px: "5px",
                                            mr: "6px"
                                        }}
                                    >
                                        {row}
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                    <Typography variant="h5" fontFamily="Fixel Display, Mulish" color="inherit" fontWeight="800" sx={{ height: "72px", mt: "8px" }}>
                        {title}
                    </Typography>
                    <Typography variant="h6" fontFamily="Fixel Display, Mulish" fontSize="12px" lineHeight="15px" color="text.secondary" sx={{ height: "30px", mt: "4px" }}>
                        {description}
                    </Typography>
                    <Typography variant="h6" fontFamily="Fixel Display, Mulish" fontWeight="600" sx={{ height: "24px", mt: "17px" }}>
                        {dayjs(date).locale('uk').format('DD MMMM')} {announcementTime}
                    </Typography>
                </BoxStyle>
            </LinkRouter>
        </Box >
    )
}

export default AnnouncementItem;