import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CloudUpload, Remove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { FC, useEffect, useState } from "react"
import { Field, FieldArray, Form, FormikProvider, getIn, useFormik } from "formik";
import * as Yup from 'yup';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import "dayjs/locale/uk";

import Dropzone from 'react-dropzone'
import { useNavigate } from "react-router-dom";

import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

import { ServerError } from "../../../../store/types";
import { IAnnouncementRequest, IAnnouncementContent, IAnnouncementImage } from "../types";

import AutocompleteComponent from "../../../../components/Autocomplete";
import { TextFieldStyle } from "../../../../components/TextField/styled";

import { toLowerFirstLetter } from "../../../../http_comon";
import TextFieldComponent from "../../../../components/TextField";
import { AutocompleteStyle } from "../../../../components/Autocomplete/style";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import MultipleAutocompleteComponent from "../../../../components/Autocomplete/multiple";
import { IHashtagInfo } from "../../Hashtag/types";
import Create from "../../Hashtag/Create";


const tomorrow = dayjs().add(1, 'day');

const AnnouncementCreate = () => {

    const { CreateAnnouncement, CreateAnnouncementImage, DeleteAnnouncementImages, GetHashtags, GetContentTypes } = useActions();
    const { hashtags } = useTypedSelector((store) => store.hashtag);
    const { contentTypes, uploadedImages } = useTypedSelector((store) => store.announcement);

    const navigate = useNavigate();

    const item: IAnnouncementRequest = {
        title: "",
        description: "",
        imagePreview: "",
        date: tomorrow,
        announcementTime: "",
        keyWords: "",
        hashtagsId: [],
        contents: [],
    }

    const validationFields = Yup.object().shape({
        title: Yup.string().min(2).max(160).required().label('Заголовок'),
        description: Yup.string().min(2).max(160).required().label('Опис'),
        date: Yup.date().min(dayjs().hour(0).minute(0).second(0).format(), `Дата, має бути пізніше ніж ${dayjs().format('DD/MM/YYYY')}`).typeError('Неправильна дата').required().label('Дата'),
        announcementTime: Yup.string().required().label('Час'),
        imagePreview: Yup.string().required().label('Зоображення'),
        keyWords: Yup.string().required("Ключові слова є обов'язкові").label('Ключові слова'),
        hashtagsId: Yup.array().min(1, "Мінімум 1 тег").max(3, "Максимум 3 теги").label('Тег'),
        contents: Yup.array()
            .of(
                Yup.object().shape({
                    content: Yup.string().required("Це поле є обов'язковим для заповнення"),
                    contentTypeId: Yup.number().min(1, "Тип контенту є обов'язковим").required().label('Тип контенту'),
                })
            )
    });

    const formik = useFormik({
        initialValues: item,
        validationSchema: validationFields,
        enableReinitialize: true,
        onSubmit: async (values, { setFieldError }) => {
            try {
                // console.log(values)
                await CreateAnnouncement(values);
                navigate("/admin");
            }
            catch (ex) {
                const serverErrors = ex as ServerError;
                if (serverErrors.errors)
                    Object.entries(serverErrors.errors).forEach(([key, value]) => {
                        if (Array.isArray(value)) {
                            let message = "";
                            value.forEach((item) => {
                                message += `${item} `;
                            });
                            setFieldError(toLowerFirstLetter(key), message);
                        }
                    });
            }
        }
    });

    const getData = async () => {
        try {
            await GetHashtags();
            await GetContentTypes();
        }
        catch (ex) {
        }
    }

    const handleUnload = async () => {
        try {
            await DeleteAnnouncementImages(uploadedImages);
        }
        catch (ex) {
        }
    }

    useEffect(() => {
        document.title = `Створити анонс`;

        getData();

        window.addEventListener("beforeunload", handleUnload);
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [formik.values.imagePreview, formik.values.contents, uploadedImages]);

    const getTypeName = (id: number) => {
        if (id === 2)
            return "Картинка";
        if (id === 3)
            return "Відео";
        return "Текст"
    };


    const { errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } = formik;

    return (
        <Box sx={{ pt: "30px" }}>
            <Typography variant="h3" fontFamily="Fixel Display, Mulish" color="text.secondary" gutterBottom sx={{ my: "auto" }}>
                Створити анонс
            </Typography>

            <Box sx={{ mt: 3 }} >
                <FormikProvider value={formik} >
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={2} direction="row" >
                            <Box sx={{ flexGrow: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "16px" }}>
                                            Карточка анонсу
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextFieldStyle
                                            fullWidth
                                            variant="standard"
                                            autoComplete="title"
                                            type="text"
                                            label='Заголовок'
                                            {...getFieldProps('title')}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"uk"}>
                                            <DatePicker
                                                label="Дата проведення"
                                                value={formik.values.date}
                                                disablePast
                                                onChange={(newValue) => {
                                                    setFieldValue("date", newValue);
                                                }}
                                                renderInput={(params) =>
                                                    <TextFieldStyle
                                                        variant="standard"
                                                        fullWidth
                                                        disabled
                                                        {...params}
                                                        {...getFieldProps('date')}
                                                        error={Boolean(touched.date && errors.date)}
                                                        helperText={touched.date && errors.date}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: "дд.мм.рррр"
                                                        }}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextFieldStyle
                                            fullWidth
                                            variant="standard"
                                            autoComplete="description"
                                            type="text"
                                            label='Опис'
                                            {...getFieldProps('description')}
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextFieldStyle
                                            fullWidth
                                            variant="standard"
                                            autoComplete="announcementTime"
                                            type="text"
                                            label='Час проведення'
                                            {...getFieldProps('announcementTime')}
                                            error={Boolean(touched.announcementTime && errors.announcementTime)}
                                            helperText={touched.announcementTime && errors.announcementTime}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldStyle
                                            fullWidth
                                            variant="standard"
                                            autoComplete="keyWords"
                                            type="text"
                                            label='Ключові слова'
                                            {...getFieldProps('keyWords')}
                                            error={Boolean(touched.keyWords && errors.keyWords)}
                                            helperText={touched.keyWords && errors.keyWords}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <MultipleAutocompleteComponent
                                            label="Теги"
                                            name={"hashtagsId"}
                                            error={typeof errors.hashtagsId === 'string' ? errors.hashtagsId : undefined}
                                            touched={touched.hashtagsId}
                                            options={hashtags}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value.id}
                                            defaultValue={undefined}
                                            onChange={async (e, value) => {
                                                let tempIds: Array<number> = value.map((item: IHashtagInfo) => { return item.id });
                                                setFieldValue("hashtagsId", tempIds)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sx={{ display: "flex", alignItems: "end" }}>
                                        <Create afterCreate={() => { getData() }} isTextButton={true} />
                                    </Grid>
                                    <Grid item xs={5} />
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "48px" }}>
                                            Контент сторінки
                                        </Typography>
                                    </Grid>
                                    {formik.values.contents.map((content: IAnnouncementContent, index) => {

                                        return (
                                            <>
                                                {index != 0 && <Grid item xs={12}>
                                                    <Divider color="#FF493B" />
                                                </Grid>}
                                                <Grid item xs={4} key={`content_type_${index}`}>
                                                    <AutocompleteStyle
                                                        disableClearable
                                                        options={contentTypes}
                                                        getOptionLabel={(option: any) => option.name}
                                                        isOptionEqualToValue={(option: any, value: any) => option?.id === value.id}
                                                        defaultValue={undefined}
                                                        onChange={async (e: any, value: any) => {
                                                            setFieldValue(`contents.${index}.contentTypeId`, value?.id)
                                                            setFieldValue(`contents.${index}.content`, "")
                                                            if (content.contentTypeId == 2)
                                                                setFieldValue(`contents.${index}.additionalContent`, "")
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextFieldStyle
                                                                {...params}
                                                                fullWidth
                                                                variant="standard"
                                                                label="Тип контенту"
                                                                name={"contentTypeId"}
                                                                error={Boolean(getIn(touched, `contents.${index}.contentTypeId`) && getIn(errors, `contents.${index}.contentTypeId`))}
                                                                helperText={getIn(touched, `contents.${index}.contentTypeId`) && getIn(errors, `contents.${index}.contentTypeId`)} />
                                                        )} />
                                                </Grid>
                                                <Grid item xs={7} key={`content_type_s_${index}`} />
                                                <Grid item xs={1} key={`content_type_delete_${index}`} sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                                                    <IconButton color="primary"
                                                        sx={{
                                                            border: 2,
                                                            borderRadius: "12px",
                                                            "&& .MuiTouchRipple-child": {
                                                                border: 0,
                                                                borderRadius: "10px",
                                                            },
                                                        }}
                                                        onClick={async () => {
                                                            let tempArr: Array<IAnnouncementContent> = formik.values.contents;
                                                            let tempContent = formik.values.contents[index];
                                                            let arrForDeleteImages: Array<string> = []

                                                            if (tempContent.contentTypeId == 2) {
                                                                if (tempContent.content != "") {
                                                                    arrForDeleteImages = [tempContent.content];
                                                                    if (tempContent.additionalContent != "")
                                                                        arrForDeleteImages = [
                                                                            ...arrForDeleteImages,
                                                                            tempContent.additionalContent
                                                                        ];
                                                                    await DeleteAnnouncementImages(arrForDeleteImages);
                                                                }
                                                            }
                                                            tempArr.splice(index, 1);
                                                            for (let i = index; i < tempArr.length; i++) {
                                                                tempArr[i].priority = i + 1;
                                                            }
                                                            formik.setFieldValue("contents", tempArr);
                                                        }}
                                                    >
                                                        <Remove />
                                                    </IconButton>
                                                </Grid>
                                                {(content.contentTypeId === 1) &&
                                                    <Grid item xs={12} key={`content_1_${index}`}>
                                                        <TextEditor
                                                            value={content.content}
                                                            setValue={(value: string) =>
                                                                setFieldValue(`contents.${index}.content`, value)
                                                            }
                                                            error={Boolean(getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))
                                                                ? (getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))
                                                                : null
                                                            }
                                                        />
                                                    </Grid>
                                                }
                                                {(content.contentTypeId === 2) &&
                                                    <Grid item xs={12} key={`content_2_${index}`} sx={{ display: "flex" }}>
                                                        <Dropzone onDrop={files => {
                                                            if (!files || files.length === 0) return;
                                                            let reader = new FileReader();
                                                            reader.readAsDataURL(files[0]);
                                                            reader.onload = async () => {
                                                                if (reader.result != null) {
                                                                    if (content.content != '')
                                                                        await DeleteAnnouncementImages([content.content]);
                                                                    let tmp = await CreateAnnouncementImage(reader.result as string) as IAnnouncementImage;
                                                                    setFieldValue(`contents.${index}.content`, tmp.name)
                                                                }
                                                            };
                                                        }}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <Box
                                                                    sx={{
                                                                        width: "250px",
                                                                        height: "220px",
                                                                        borderRadius: "10px",
                                                                        cursor: "pointer",
                                                                        border: theme => `1px solid ${(Boolean(getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))) ? theme.palette.error.main : theme.palette.primary.light}`,
                                                                        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                                        mr: "35px"
                                                                    }}
                                                                >
                                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                                        <input {...getInputProps()} />
                                                                        {content.content != ''
                                                                            ? <Box sx={{ height: "200px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                                                                <img
                                                                                    src={content.content}
                                                                                    alt="imagePreview"
                                                                                    style={{
                                                                                        width: "200px",
                                                                                        height: "160px",
                                                                                        objectFit: "scale-down"
                                                                                    }}
                                                                                />
                                                                                <Typography variant="subtitle1" color="inherit" align="center">
                                                                                    Натисніть на фото для зміни
                                                                                </Typography>
                                                                            </Box>
                                                                            : <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                                <CloudUpload sx={{ fontSize: 30 }} color={(Boolean(getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))) ? "error" : "inherit"} />
                                                                                <Typography variant="subtitle1" color={(Boolean(getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))) ? "error" : "inherit"} align="center">
                                                                                    {Boolean(getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))
                                                                                        ? "Зоображення є обов'язковим"
                                                                                        : "Натисніть для пошуку фото, щоб завантажити"
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                    </div>
                                                                </Box>
                                                            )}
                                                        </Dropzone>
                                                        {content.content != "" &&
                                                            <Dropzone onDrop={files => {
                                                                if (!files || files.length === 0) return;
                                                                let reader = new FileReader();
                                                                reader.readAsDataURL(files[0]);
                                                                reader.onload = async () => {
                                                                    if (reader.result != null) {
                                                                        if (content.additionalContent != '')
                                                                            await DeleteAnnouncementImages([content.additionalContent]);
                                                                        let tmp = await CreateAnnouncementImage(reader.result as string) as IAnnouncementImage;
                                                                        setFieldValue(`contents.${index}.additionalContent`, tmp.name)
                                                                    }
                                                                };
                                                            }}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <Box
                                                                        sx={{
                                                                            width: "250px",
                                                                            height: "220px",
                                                                            borderRadius: "10px",
                                                                            cursor: "pointer",
                                                                            border: theme => `1px solid ${theme.palette.primary.light}`,
                                                                            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
                                                                        }}
                                                                    >
                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                            <input {...getInputProps()} />
                                                                            {content.additionalContent != ''
                                                                                ? <Box sx={{ height: "200px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                                                                    <img
                                                                                        src={content.additionalContent}
                                                                                        alt="imagePreview"
                                                                                        style={{
                                                                                            width: "200px",
                                                                                            height: "160px",
                                                                                            objectFit: "scale-down"
                                                                                        }}
                                                                                    />
                                                                                    <Typography variant="subtitle1" color="inherit" align="center">
                                                                                        Натисніть на фото для зміни
                                                                                    </Typography>
                                                                                </Box>
                                                                                : <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                                    <CloudUpload sx={{ fontSize: 30 }} color="inherit" />
                                                                                    <Typography variant="subtitle1" color="inherit" align="center">
                                                                                        Натисніть для пошуку додаткового фото, щоб завантажити
                                                                                    </Typography>
                                                                                </Box>
                                                                            }
                                                                        </div>
                                                                    </Box>
                                                                )}
                                                            </Dropzone>}
                                                    </Grid>
                                                }
                                                {(content.contentTypeId === 3) &&
                                                    <Grid item xs={12} key={`content_3_${index}`}>
                                                        <TextFieldStyle
                                                            fullWidth
                                                            variant="standard"
                                                            type="text"
                                                            label={getTypeName(content.contentTypeId)}
                                                            {...getFieldProps(`contents.${index}.content`)}
                                                            error={Boolean(getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`))}
                                                            helperText={getIn(touched, `contents.${index}.content`) && getIn(errors, `contents.${index}.content`)}
                                                        />
                                                    </Grid>
                                                }
                                            </>
                                        )
                                    })}
                                    <Grid item xs={12} sx={{ justifyContent: "center" }}>
                                        <Button
                                            sx={{ width: "100%", height: "100%", paddingY: "25px" }}
                                            size="large"
                                            variant="outlined"
                                            onClick={() => {
                                                const newContents = [
                                                    ...formik.values.contents,
                                                    {
                                                        content: "",
                                                        additionalContent: "",
                                                        priority: formik.values.contents.length + 1,
                                                        contentTypeId: 0
                                                    }
                                                ];
                                                formik.setFieldValue("contents", newContents);
                                            }}
                                        >
                                            Додати
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "end", flexGrow: 1 }}>
                                <Dropzone onDrop={files => {
                                    if (!files || files.length === 0) return;
                                    let reader = new FileReader();
                                    reader.readAsDataURL(files[0]);
                                    reader.onload = async () => {
                                        if (reader.result != null) {
                                            if (formik.values.imagePreview != '')
                                                await DeleteAnnouncementImages([formik.values.imagePreview]);
                                            let tmp = await CreateAnnouncementImage(reader.result as string) as IAnnouncementImage;
                                            setFieldValue("imagePreview", tmp.name)
                                        }
                                    };
                                }}>
                                    {({ getRootProps, getInputProps }) => (
                                        <Box
                                            sx={{
                                                width: "250px",
                                                height: "220px",
                                                borderRadius: "10px",
                                                cursor: "pointer",
                                                border: theme => `1px solid ${(Boolean(touched.imagePreview && errors.imagePreview)) ? theme.palette.error.main : theme.palette.primary.light}`,
                                                display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
                                            }}
                                        >
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                {formik.values.imagePreview != ''
                                                    ? <Box sx={{ height: "200px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                                        <img
                                                            src={formik.values.imagePreview}
                                                            alt="imagePreview"
                                                            style={{
                                                                width: "200px",
                                                                height: "160px",
                                                                objectFit: "scale-down"
                                                            }}
                                                        />
                                                        <Typography variant="subtitle1" color="inherit" align="center">
                                                            Натисніть на фото для зміни
                                                        </Typography>
                                                    </Box>
                                                    : <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <CloudUpload sx={{ fontSize: 30 }} color={(Boolean(touched.imagePreview && errors.imagePreview)) ? "error" : "inherit"} />
                                                        <Typography variant="subtitle1" color={(Boolean(touched.imagePreview && errors.imagePreview)) ? "error" : "inherit"} align="center">
                                                            {Boolean(touched.imagePreview && errors.imagePreview)
                                                                ? (touched.imagePreview && errors.imagePreview)
                                                                : "Натисніть для пошуку фото, щоб завантажити"
                                                            }
                                                        </Typography>
                                                    </Box>
                                                }
                                            </div>
                                        </Box>
                                    )}
                                </Dropzone>
                            </Box>
                        </Stack>

                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                            <LoadingButton
                                sx={{ paddingX: "35px", mt: "30px" }}
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Створити
                            </LoadingButton>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Box >
        </Box >
    )
}

export default AnnouncementCreate