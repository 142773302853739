export enum HashtagActionTypes {
    GET_HASHTAGS = "GET_HASHTAGS",
    GET_BY_ID_HASHTAG = "GET_BY_ID_HASHTAG",
    CREATE_HASHTAG = "CREATE_HASHTAG",
    UPDATE_HASHTAG = "UPDATE_HASHTAG",
    SEARCH_HASHTAGS = "SEARCH_HASHTAGS"
}

export interface IHashtag {
    name: string
}

export interface IHashtagInfo {
    id: number,
    name: string
}

export interface ISearchHashtags {
    count: number,
    values: Array<IHashtagInfo>
}

export interface HashtagState {
    selectedHashtag: IHashtag,
    count: number,
    hashtags: Array<IHashtagInfo>
}


export interface GetHashtagsAction {
    type: HashtagActionTypes.GET_HASHTAGS,
    payload: Array<IHashtagInfo>
}

export interface SearchHashtagsAction {
    type: HashtagActionTypes.SEARCH_HASHTAGS,
    payload: ISearchHashtags
}

export interface GetByIdHashtagAction {
    type: HashtagActionTypes.GET_BY_ID_HASHTAG,
    payload: IHashtag
}

export interface CreateHashtagAction {
    type: HashtagActionTypes.CREATE_HASHTAG,
    payload: IHashtag
}

export interface UpdateHashtagAction {
    type: HashtagActionTypes.UPDATE_HASHTAG,
    payload: IHashtag
}


export type HashtagAction = GetHashtagsAction |
    SearchHashtagsAction |
    GetByIdHashtagAction |
    CreateHashtagAction |
    UpdateHashtagAction;