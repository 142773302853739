import { Box, styled } from "@mui/material";


export const BoxStyle = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.dark,

    height: "348px",
    [theme.breakpoints.only('xl')]: {
        width: "303px",
    },
    [theme.breakpoints.down('xl')]: {
        width: "285px",
    },

    borderRadius: "8px",

    paddingTop: "15px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingBottom: "10px",

    display: "flex",
    flexDirection: "column",
}));

