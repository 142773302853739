import { Dayjs } from "dayjs";

export enum AnnouncementUserActionTypes {
    GET_NOVELTIES = "GET_NOVELTIES",
    ADD_NOVELTIES = "ADD_NOVELTIES",
}

export interface IAnnouncementItem {
    urlSlug: string,
    title: string,
    description: string,
    imagePreview: string,
    date: Dayjs,
    announcementTime: string,
    hashtags: Array<string>
}

export interface IAnnouncementResponse {
    count: number,
    values: Array<IAnnouncementItem>
}


export interface AnnouncementUserState {
    count: number,
    announcements: Array<IAnnouncementItem>,
}


export interface GetNoveltiesAction {
    type: AnnouncementUserActionTypes.GET_NOVELTIES,
    payload: IAnnouncementResponse
}
export interface AddNoveltiesAction {
    type: AnnouncementUserActionTypes.ADD_NOVELTIES,
    payload: IAnnouncementResponse
}


export type AnnouncementUserAction = GetNoveltiesAction | AddNoveltiesAction;