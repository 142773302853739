import { styled, TextField, Button, Drawer, Typography, Link, Box } from "@mui/material";

export const DonationButtonStyle = styled(Button)(({ theme }) => ({
    fontFamily: "Fixel Display, Mulish",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    minWidth: "auto",
    height: "auto",
    padding: "7px 15px 3px 15px",
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: "4px",
    fontStyle: "normal",
    "&:hover": { background: theme.palette.primary.main },
    "&& .MuiTouchRipple-child": { backgroundColor: theme.palette.primary.main },
}));