import {
    Box,
    Typography,
    IconButton,
    Grid,
    ListItem,
    ListItemIcon,
    Paper,
    useTheme,
    ImageList,
    ImageListItem,
} from '@mui/material'
import {
    ArrowBackIosNewOutlined,
    ArrowForwardIosOutlined,
    KeyboardArrowRight,
    PhotoOutlined,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

import LinkRouter from '../../../components/LinkRouter';

import { home_page_main, partner_1, partner_2, partner_3, reporting_1, reporting_2, reporting_3, reporting_4, reporting_5, team_member_1, team_member_2, team_member_3 } from '../../../assets/backgrounds';
import { arrow_circle, arrow_down, arrow_left, arrow_right, audit_outlined, read_outlined, read_outlined_white, reconciliation_outlined, snippets_outlined, solution_outlined, team_outlined } from '../../../assets/icons';

import { ChronologyBoxStyle, MoreInfoButtonStyle } from './styled';
import Chronology from './chronology';



const HomePage = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [images, setPages] = useState<Array<string>>([
        reporting_1,
        reporting_2,
        reporting_3,
        reporting_4,
        reporting_5,
    ]);

    const [partners, setPartners] = useState<Array<string>>([
        partner_1,
        partner_2,
        partner_3,
        partner_1,
        partner_2,
        partner_3,
        partner_1,
        partner_2,
        partner_3,
        partner_1,
        partner_2,
        partner_3,
    ]);



    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
        } catch (ex) {
        }
    };

    return (
        <Grid container spacing={0} sx={{ pt: "70px", mb: "153px" }}>
            <Grid item xs={8}>
                <Typography variant="h1" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="50px" lineHeight="62px" sx={{ mt: "33px" }}>
                    Об’єднані культурою - творимо історію
                </Typography>
                <Typography variant="h3" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ mt: "34px" }}>
                    Реалізуємо проєкти, які залучають молодь до пізнання української культури та допомагають формувати свідоме демократичне суспільство. Культура для нас — це основа об’єднання та розвитку.
                </Typography>
                <MoreInfoButtonStyle sx={{ mt: "34px" }}>
                    Наші заходи тут
                </MoreInfoButtonStyle>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                    src={home_page_main}
                    alt="home_page_main"
                />
            </Grid>
            {/* Про нас */}
            <Grid item xs={12} sx={{ mt: "106px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Про нас
                </Typography>
                <Typography variant="h4" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ mt: "34px" }}>
                    Про тебе і Україну — це громадська організація, заснована в листопаді 2022 року, яка працює над розвитком демократичного суспільства через популяризацію та збереження української культури
                </Typography>
                <Typography variant="h4" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ mt: "20px" }}>
                    Ми реалізуємо свою діяльність у понад п’яти областях України, залучаючи молодь до неформальної освіти, культурно-просвітницьких заходів і творчих ініціатив. Наші інтерактивні проєкти поєднують історичний контекст із сучасними мультимедійними форматами, що допомагає глибше зрозуміти суспільні процеси та їхній вплив на сьогодення.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end", mt: "20px" }}>
                    <MoreInfoButtonStyle>
                        Дізнатися більше
                    </MoreInfoButtonStyle>
                </Box>
            </Grid>
            {/* Наша місія/візія */}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mt: "120px" }}>
                <Box
                    sx={{
                        width: "640px",
                        height: "300px",
                        background: (theme) => theme.palette.primary.dark,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        position: "relative"
                    }}
                >
                    <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px" sx={{ pt: "21px", px: "22px" }}>
                        Наша місія
                    </Typography>
                    <Typography variant="h4" fontFamily="Fixel Display, Mulish" sx={{ pt: "14px", px: "22px" }}>
                        Сприяти залученню молоді до пізнання та розвитку української культури, демонструючи її ключову роль у формуванні демократичного суспільства.
                    </Typography>
                    <IconButton disableRipple size="large" sx={{ position: "absolute", right: "28px", bottom: "14px", border: `2px solid #fff`, p: "0px" }}>
                        <KeyboardArrowRight fontSize="inherit" />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: "640px",
                        height: "300px",
                        background: (theme) => theme.palette.primary.dark,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        position: "relative"
                    }}
                >
                    <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px" sx={{ pt: "21px", px: "22px" }}>
                        Наша візія
                    </Typography>
                    <Typography variant="h4" fontFamily="Fixel Display, Mulish" sx={{ pt: "14px", px: "22px" }}>
                        Свідоме українське суспільство, в якому кожен розуміє значення культурної та історичної спадщини, активно зберігає й передає їх майбутнім поколінням.
                    </Typography>
                    <IconButton disableRipple size="large" sx={{ position: "absolute", right: "28px", bottom: "14px", border: `2px solid #fff`, p: "0px" }}>
                        <KeyboardArrowRight fontSize="inherit" />
                    </IconButton>
                </Box>
            </Grid>
            {/* Наша діяльність */}
            <Grid item xs={8} sx={{ mt: "128px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наша діяльність
                </Typography>
                <Typography variant="h5" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "16px" }}>
                    Ми проводимо навчальні сесії та тренінги, спрямовані на підвищення обізнаності про українську<br />культуру, історію, а також на розвиток навичок, необхідних для підтримки та просування українських<br />інтересів у світі. Наші тренінги допомагають учасникам глибше зрозуміти<br />українські реалії та набути знань.
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ mt: "128px" }}>
                <Box
                    sx={{
                        width: "394px",
                        height: "137px",
                        background: (theme) => theme.palette.text.secondary,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        display: "flex"
                    }}
                >
                    <Box sx={{ pt: "7px", pl: "11px" }}>
                        <img
                            src={audit_outlined}
                            alt="audit_outlined"
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        fontFamily="Fixel Display, Mulish"
                        fontWeight={600}
                        fontSize="24px"
                        lineHeight="24px"
                        textTransform="uppercase"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            alignContent: "center",
                            p: "7px 15px 7px 20px",
                        }}
                    >
                        Збереження та популяризація української культурної спадщини
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                    sx={{
                        width: "394px",
                        height: "137px",
                        background: (theme) => theme.palette.text.secondary,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        display: "flex"
                    }}
                >
                    <Box sx={{ pt: "7px", pl: "11px" }}>
                        <img
                            src={read_outlined}
                            alt="read_outlined"
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        fontFamily="Fixel Display, Mulish"
                        fontWeight={600}
                        fontSize="22px"
                        lineHeight="24px"
                        textTransform="uppercase"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            alignContent: "center",
                            p: "7px 15px 7px 20px",
                        }}
                    >
                        Неформальна освіта для молоді
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        width: "394px",
                        height: "137px",
                        background: (theme) => theme.palette.text.secondary,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        display: "flex"
                    }}
                >
                    <Box sx={{ pt: "7px", pl: "11px" }}>
                        <img
                            src={team_outlined}
                            alt="team_outlined"
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        fontFamily="Fixel Display, Mulish"
                        fontWeight={600}
                        fontSize="22px"
                        lineHeight="24px"
                        textTransform="uppercase"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            alignContent: "center",
                            p: "7px 0px 7px 20px",
                        }}
                    >
                        Мистецька діяльність задля розвитку демократичних цінностей українського суспільства
                    </Typography>
                </Box>
            </Grid>
            {/* Наша звітність */}
            <Grid item xs={12} sx={{ mt: "120px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наша звітність
                </Typography>
                <Box sx={{ height: "650px", mt: "34px" }}>
                    <ImageList variant="masonry" cols={3} gap={13} sx={{ width: "fit-content" }}>
                        {images.map((item, index) => (
                            <ImageListItem key={`reporting_${index}`} sx={{ width: "fit-content" }}>
                                <img
                                    src={item}
                                    alt={`reporting_${index}`}
                                    loading="lazy"
                                    style={{ width: "fit-content" }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: "32px" }}>
                    <MoreInfoButtonStyle>
                        Дізнатися більше
                    </MoreInfoButtonStyle>
                </Box>
            </Grid>
            {/* Наша команда */}
            <Grid item xs={12} sx={{ mt: "94px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наша команда
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pt: "31px" }}>
                    <img
                        src={team_member_1}
                        alt="team_member_1"
                    />
                    <Box>
                        <img
                            src={team_member_2}
                            alt="team_member_2"
                        />
                        <LinkRouter underline="always" color="inherit" to="/">
                            <Typography variant="h4" fontFamily="Fixel Display" textAlign="center" sx={{ mt: "48px" }}>
                                Більше...
                            </Typography>
                        </LinkRouter>
                    </Box>
                    <img
                        src={team_member_3}
                        alt="team_member_3"
                    />
                </Box>
            </Grid>
            {/* Хронологія */}
            <Grid item xs={12} sx={{ mt: "118px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Хронологія
                </Typography>
            </Grid>
            <Chronology />
            {/* Наші партнери */}
            <Grid item xs={12} sx={{ mt: "78px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наші партнери
                </Typography>
                <Box sx={{ mt: "32px" }}>
                    <Swiper
                        modules={[Navigation]}
                        navigation={true}
                        slidesPerView={7}
                        slidesPerGroup={1}
                        spaceBetween={24}
                    >
                        {partners.map((item, index) => {
                            return (
                                <SwiperSlide key={`partner_${index}`}>
                                    <img
                                        src={item}
                                        alt={`partner_${index}`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Box>
            </Grid>
            {/* Контакти */}
            <Grid item xs={12} sx={{ mt: "130px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Контакти
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: "30px" }}>
                    <Box
                        sx={{
                            background: palette.text.secondary,
                            width: "375px",
                            borderRadius: "10px",
                            p: "24px 24px 24px 34px",
                            position: "relative"
                        }}
                    >
                        <Typography variant="h3" fontFamily="Fixel Display, Mulish" color="primary.dark" fontWeight="bold">
                            Знайди нас тут!
                        </Typography>
                        <Typography variant="h5" fontFamily="Fixel Display, Mulish" color={palette.background.default} fontWeight="600" sx={{ mt: "7px" }}>
                            Підписуйтесь на наші сторінки в соцмережах, щоб слідкувати за нашою діяльністю та залишатися в курсі подій
                        </Typography>
                        <IconButton disableRipple size="large" sx={{ color: palette.background.default, position: "absolute", right: "18px", bottom: "19px", border: `2px solid ${palette.background.default}`, p: "0px" }}>
                            <KeyboardArrowRight fontSize="inherit" />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            background: palette.primary.dark,
                            width: "540px",
                            border: `1px solid ${palette.text.secondary}`,
                            borderRadius: "10px",
                            p: "24px 24px 24px 34px",
                            position: "relative"
                        }}
                    >
                        <Typography variant="h3" fontFamily="Fixel Display, Mulish" color="inherit" fontWeight="bold">
                            Підтримай нас тут
                        </Typography>
                        <Typography variant="h5" fontFamily="Fixel Display, Mulish" color={palette.text.secondary} fontWeight="600">
                            Ваш внесок допоможе<br /> нам продовжувати нашу роботу.
                        </Typography>
                        <IconButton disableRipple size="large" sx={{ position: "absolute", right: "18px", bottom: "19px", border: `2px solid #fff`, p: "0px" }}>
                            <KeyboardArrowRight fontSize="inherit" />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            background: palette.text.secondary,
                            width: "375px",
                            borderRadius: "10px",
                            p: "24px 24px 24px 34px",
                            position: "relative"
                        }}
                    >
                        <Typography variant="h3" fontFamily="Fixel Display, Mulish" color="primary.dark" fontWeight="bold">
                            Давай співпрацювати!
                        </Typography>
                        <Typography variant="h5" fontFamily="Fixel Display, Mulish" color={palette.background.default} fontWeight="600">
                            Ми відкриті до нових партнерств та колаборацій. Разом зможемо зробити більше для України!
                        </Typography>
                        <IconButton disableRipple size="large" sx={{ color: palette.background.default, position: "absolute", right: "18px", bottom: "19px", border: `2px solid ${palette.background.default}`, p: "0px" }}>
                            <KeyboardArrowRight fontSize="inherit" />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomePage