import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import { FC, } from "react";


interface Props {
    onClick: () => void
}

const IconButtonPlus: FC<Props> = ({ onClick }) => {
    return (
        <IconButton
            size="large"
            color="inherit"
            onClick={onClick}
            sx={{
                fontSize: "30px",
                borderRadius: '12px',
                background: "#F45626",
                "&:hover": {
                    background: "#CB2525"
                },
                "&& .MuiTouchRipple-child": {
                    backgroundColor: "transparent"
                }
            }}
        >
            <AddIcon />
        </IconButton>
    )
}

export default IconButtonPlus;