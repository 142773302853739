import {
    Typography,
    Breadcrumbs
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { FC } from 'react';

import LinkRouter from '../LinkRouter';

import { IBreadcrumb } from './types';

import { breadcrumb_home, breadcrumb_separator } from '../../assets/icons';



interface IProps {
    elements: Array<IBreadcrumb>,
}

const BreadcrumbsComponent: FC<IProps> = ({ elements }) => {

    return (
        <Breadcrumbs
            color="inherit"
            separator={<img
                src={breadcrumb_separator}
                alt="breadcrumb_separator"
            />}
            sx={{
                height: "22px",
                ".MuiBreadcrumbs-ol": {
                    height: "22px",
                },
                ".MuiBreadcrumbs-li": {
                    height: "22px",
                },
                ".MuiBreadcrumbs-separator": {
                    margin: 0
                }
            }}
        >
            <LinkRouter underline="none" color="inherit" to="/" sx={{ height: "22px" }}>
                <img
                    src={breadcrumb_home}
                    alt="breadcrumb_home"
                />
            </LinkRouter>
            {elements.map((value, index) => {
                const last = index === elements.length - 1;

                return last ? (
                    <Typography color="primary.main" fontSize="14px" lineHeight="18px" fontFamily="Fixel Display, Mulish" key={`breadcrumb_${index}`} sx={{ padding: "3px 4px 1px 4px" }}>
                        {value.name}
                    </Typography>
                ) : (
                    <LinkRouter underline="none" color="primary.main" to={value.urlSlug} key={`breadcrumb_${index}`}>
                        <Typography fontSize="14px" lineHeight="18px" fontFamily="Fixel Display, Mulish" sx={{ padding: "3px 4px 1px 4px" }}>
                            {value.name}
                        </Typography>
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadcrumbsComponent;