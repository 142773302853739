import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "./Header";


const AdminLayout = () => {
    return (
        <>
            <Header />
            <Container component="main" sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md" } }}>
                <Box sx={{ px: "65px" }}>
                    <Outlet />
                </Box>
            </Container>
        </>
    );
};

export default AdminLayout;