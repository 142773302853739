import * as AuthActionCreators from "../../pages/auth/actions"

import * as AdminAnnouncementActionCreators from "../../pages/admin/Announcement/actions"
import * as AnnouncementActionCreators from "../../pages/default/Announcements/actions"
import * as AdminHashtagActionCreators from "../../pages/admin/Hashtag/actions"
import * as UIActionCreators from "../../UISettings/actions"

const actions = {
    ...AuthActionCreators,
    ...AdminAnnouncementActionCreators,
    ...AnnouncementActionCreators,
    ...AdminHashtagActionCreators,
    ...UIActionCreators
}
export default actions;