import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Header from '../../containers/DefaultLayout/Header';

import LinkRouter from '../../components/LinkRouter';

const NotFound = () => {
    return (
        <>
            <Header />
            <Container component="main"
                sx={{
                    maxWidth: { xl: "xl", lg: "lg", md: "md" },
                    marginTop: "153px",
                    textAlign: "center",
                }}
            >
                <Box>
                    <Typography color="inherit" fontSize="150px" lineHeight="188px" fontWeight="600" sx={{ marginBottom: "20px" }}>404</Typography>
                    <Typography variant="h1" color="inherit" sx={{ marginBottom: "20px" }}>Page not found</Typography>
                    <Typography variant="h4" color="inherit" fontWeight="medium" sx={{ marginBottom: "95px" }}>
                        Unfortunately, the page you asked for could not be found.
                        <br />
                        Please go back to the main page.
                    </Typography>
                    <LinkRouter underline="none" to="/">
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: "20px",
                                lineHeight: "25px",
                                fontWeight: "500",
                                borderRadius: "10px",
                                px: "65px",
                                py: "15px",
                                textTransform: "none",
                            }}
                        >
                            Main
                        </Button>
                    </LinkRouter>
                </Box>
            </ Container>
        </>
    );
}

export default NotFound;