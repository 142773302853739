export const telegram_outlined = "/assets/icons/telegram_outlined.png";
export const instagram_outlined = "/assets/icons/instagram_outlined.png";
export const wechat_outlined = "/assets/icons/wechat_outlined.png";
export const whats_app_outlined = "/assets/icons/whats_app_outlined.png";
export const arrow_circle = "/assets/icons/arrow_circle.png";
export const audit_outlined = "/assets/icons/audit_outlined.png";
export const read_outlined = "/assets/icons/read_outlined.png";
export const team_outlined = "/assets/icons/team_outlined.png";
export const breadcrumb_home = "/assets/icons/breadcrumb_home.png";
export const breadcrumb_separator = "/assets/icons/breadcrumb_separator.png";
export const read_outlined_white = "/assets/icons/read_outlined_white.png";
export const reconciliation_outlined = "/assets/icons/reconciliation_outlined.png";
export const snippets_outlined = "/assets/icons/snippets_outlined.png";
export const solution_outlined = "/assets/icons/solution_outlined.png";
export const arrow_left = "/assets/icons/arrow_left.png";
export const arrow_right = "/assets/icons/arrow_right.png";
export const arrow_down = "/assets/icons/arrow_down.png";

