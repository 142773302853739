import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import * as Yup from "yup"

import { getLocalAccessToken } from "./http_comon"
import { useActions } from './hooks/useActions';
import { useTypedSelector } from './hooks/useTypedSelector';

import DefaultLayout from './containers/DefaultLayout';
import AdminLayout from './containers/AdminLayout';

import HomePage from './pages/default/HomePage';
import SignInPage from './pages/auth';

import AnnouncementTable from './pages/admin/Announcement/Table';
import AnnouncementCreate from './pages/admin/Announcement/Create';
import HashtagTable from './pages/admin/Hashtag/Table';

import NotFound from './pages/notfound';

import darkTheme from './UISettings/darkTheme';
import lightTheme from './UISettings/lightTheme';
import Announcements from './pages/default/Announcements';




const App = () => {
  const { AuthUser } = useActions();
  const { isAuth, user } = useTypedSelector(store => store.auth);

  const { SetTheme } = useActions();
  const { isDarkTheme } = useTypedSelector((state) => state.ui);

  const darkThemeLS = localStorage.darkTheme == "true";

  useEffect(() => {
    if (darkThemeLS) {
      SetTheme(darkThemeLS);
    }
  }, []);

  Yup.setLocale({
    mixed: {
      required: label => `${label.label} є обов'язковим`,
    },
    string: {
      min: label => `${label.label}, повинно бути принаймні ${label.min} символів`,
      max: label => `${label.label}, має бути менше ніж ${label.max} символів`,
    },
    number: {
      min: label => `${label.label}, повинно бути принаймні ${label.min}`,
      max: label => `${label.label}, має бути менше ніж ${label.max}`,
    }
  })

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark" />
        <Routes>

          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<HomePage />} />
            <Route path="announcements" element={<Announcements />} />
          </Route>

          {/* <Route element={<AuthLayout />}>
            <Route path="/auth/signin" element={<SignIn />} />
            <Route path="/auth/signup" element={<SignUp />} />
          </Route> */}

          {isAuth ?
            <>
              {user.role == "Admin" && <Route path="/admin/" element={<AdminLayout />}>
                <Route index element={<AnnouncementTable />} />
                <Route path="announcements/create" element={<AnnouncementCreate />} />
                <Route path="projects" element={<AnnouncementTable />} />
                <Route path="hashtags" element={<HashtagTable />} />
              </Route>}
            </>
            : <>
              <Route path="/admin" element={<SignInPage />} />
            </>
          }
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
