import {
    Box,
    Container,
    Grid,
    Typography
} from "@mui/material";
import { useState } from "react";

import LinkRouter from "../../components/LinkRouter";

import { logo } from "../../assets/logos";
import { instagram_outlined, telegram_outlined, wechat_outlined, whats_app_outlined } from "../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";


interface IAppBarItem {
    lable: string,
    path: string,
}

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [firstPages, setFirstPages] = useState<Array<IAppBarItem>>([
        { lable: 'Головна', path: '/' },
        { lable: 'Про нас', path: '/' },
    ]);
    const [secondPages, setSecondPages] = useState<Array<IAppBarItem>>([
        { lable: 'Анонси', path: '/' },
        { lable: 'Як проводим?', path: '/' },
        { lable: 'Проєкти', path: '/' },
    ]);
    const [socialMedia, setSocialMedia] = useState<Array<IAppBarItem>>([
        { lable: telegram_outlined, path: '/' },
        { lable: instagram_outlined, path: '/' },
        { lable: wechat_outlined, path: '/' },
        { lable: whats_app_outlined, path: '/' },
    ]);

    return (
        <Box component="footer" sx={{ borderTop: (theme) => `1px solid ${theme.palette.primary.light}` }}>
            <Container sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md" } }}>
                <Grid container spacing={0} sx={{ height: "100%", display: "flex", px: "65px", pt: "40px", pb: "27px" }}>
                    <Grid item xs={2}>
                        <LinkRouter underline="none" color="unset" to="/" sx={{ height: "35px" }}>
                            <img
                                src={logo}
                                alt="logo"
                            />
                        </LinkRouter>
                        <Typography variant="h4" fontFamily="Fixel Display" sx={{ pt: "50px" }}>
                            +(380) 97-84-55-095
                        </Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Grid container direction="column" rowSpacing={3} sx={{ pl: "50px" }}>
                            {firstPages.map((page, index) => (
                                <Grid item key={`first_pages_${index}`}>
                                    <LinkRouter underline="none" color="unset" to={page.path}>
                                        <Typography variant="h4" fontFamily="Fixel Display" >
                                            {page.lable}
                                        </Typography>
                                    </LinkRouter>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container direction="column" rowSpacing={3} sx={{ pl: "40px" }}>
                            {secondPages.map((page, index) => (
                                <Grid item key={`second_pages_${index}`}>
                                    <LinkRouter underline="none" color="unset" to={page.path}>
                                        <Typography variant="h4" fontFamily="Fixel Display" >
                                            {page.lable}
                                        </Typography>
                                    </LinkRouter>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
                        {socialMedia.map((item, index) => (
                            <Box
                                key={`social_media_${index}`}
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                    borderRadius: "5px",
                                    ml: "24px"
                                }}
                            >
                                <img
                                    src={item.lable}
                                    alt={`social_media_${index}`}
                                />
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0} direction="column" alignItems="center" sx={{ pt: "60px" }}>
                            <Box sx={{ display: "flex" }}>
                                <Typography variant="h5" fontFamily="Fixel Display" >
                                    © 2014-2024 AboutUkraine.&nbsp;
                                </Typography>
                                <LinkRouter underline="always" color="inherit" to="/">
                                    <Typography variant="h5" fontFamily="Fixel Display" >
                                        Правила використання сайту.
                                    </Typography>
                                </LinkRouter>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <Typography variant="h5" fontFamily="Fixel Display" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { window.open(`${location.pathname.split("/")[0]}/files/Договір оферти.docx.pdf`) }}>
                                    Договір оферта
                                </Typography>
                            </Box>
                            <Typography variant="h5" fontFamily="Fixel Display" color="primary.light" sx={{ pt: "18px", cursor: "pointer" }} onClick={() => { window.open("https://www.instagram.com/kova.design_studio/") }}>
                                created by kova.design studio
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
}

export default Footer;